
    @import "config.scss";
 
.container {
  background-color: var(--color-black);
  display: flex;
  justify-content: space-between;
  align-items: center; // cuando NO tengas logos de eventos en home
  //   padding-block: 0.5rem;
  min-height: 40px;

  @include for-desktop-up {
    gap: 2rem;
  }
}

.header {
  background-color: var(--color-black);
  padding: 0.5rem 1rem;
  position: relative;
  z-index: var(--z-content-top);
  // margin-bottom: 1rem;

  .mobileContainer {
    display: flex;
    flex-direction: row;
    top: 0;
    align-items: center;

    @include for-desktop-up {
      display: none;
    }

    .logoContainer {
      margin-right: auto;
    }

    .loginLogos {
      display: flex;
    }

    .itemCount {
      background-color: white;
      border-radius: 50px;
      height: 28%;
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 12px;
      right: 1px;
    }

    .cart {
      position: relative;
      margin-left: 5px;
    }
  }

  .tabletContainer {
    display: none;

    @include for-desktop-up {
      display: flex;
      align-items: center;
      gap: 30px;
      width: 100%;
      justify-content: space-between;
    }

    .cart {
      position: relative;
      margin-left: 5px;
    }

    .searchbar {
      width: 60%;
    }

    .itemCount {
      background-color: white;
      border-radius: 50px;
      height: 28%;
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 12px;
      right: 1px;
    }

    .loginLogos {
      display: flex;
      align-items: center;
    }
  }
}
.desktopPadding {
  @include for-desktop-up {
    padding-top: 50px;
  }
}
.logo {
  cursor: pointer;
  position: absolute;
  left: 50%;
  translate: -50% -15px;

  @include for-desktop-up {
    position: relative;
    padding: 1rem;
    left: auto;
    translate: none;
  }
}

.slider {
  display: none;
  @include for-desktop-up {
    background: linear-gradient(0deg, #e3e3e3, white);
    width: 100%;
    height: 50px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    position: absolute;
    left: 0;
    top: 0;
  }

  .headerBrands {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;

    .leftFlag {
      border-left: 20px solid var(--color-primary);
      border-bottom: 50px solid transparent;
      left: 0;
    }

    .rightFlag {
      border-right: 20px solid var(--color-primary);
      border-top: 50px solid transparent;
      right: 0;
    }
  }

  .logo {
    height: 10px;
    display: flex;
    align-items: center;
  }

  a {
    scroll-snap-align: start;
  }
}

.navbar {
  display: none;
  z-index: var(--z-content-top);
  padding-inline: 2rem;

  @include for-desktop-up {
    color: white;
    border-radius: 30px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin: 10px 0;
    padding: 0.5rem 0;
    padding-inline: 1rem;
  }
}

.overlay {
  position: absolute;
  top: 100px;
  width: 100%;
}

.secondLine {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  position: sticky;
  gap: 10px;
  top: -0.05rem;
  z-index: var(--z-nav-fixed);
  background-color: var(--color-black);
  padding-block: 0.5rem;
  padding-inline: 1rem;

  .searcher {
    flex: 1;
  }

  @include for-desktop-up {
    display: none;
  }
}
